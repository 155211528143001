import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const useStyles = makeStyles(() => ({
  noPadding: {
    padding: 0
  },
  marginBasic: {
    marginLeft: '9px',
    marginRight: '9px'
  }
}));

export default function CheckboxInput({
  label,
  name,
  value,
  onChange,
  required,
  error,
  disabled,
  helperText
}) {
  const classes = useStyles();
  const handleChange = (e) => {
    onChange({ target: { name, value: e.target.checked } });
  };

  return (
    <FormControl error={error} component="fieldset">
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            checked={value || false}
            onChange={handleChange}
            name={name}
            className={clsx(classes.noPadding, classes.marginBasic)}
          />
        }
        label={label}
        className={`form-label ${required ? ' required' : ''}`}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
