import React from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';
import DateInput from '../../../UI/input/DateInput';

import axios from 'src/axios';

import { Box, TextField } from '@mui/material';

import {
  SnackContext,
  AppContext,
  ThemeContext,
  UserContext
} from 'src/store/ContextStore';
import { getDependencies } from './formService';
import TextInput from 'src/components/UI/input/TextInput';
import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';
import SelectInput from 'src/components/UI/input/SelectInput';

export default function ConsentForm({
  handleClose,
  familyMemberId,
  isEditing,
  model,
  staffMembers,
  staffMemberOptions
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { user: loggedInUser } = React.useContext(UserContext);

  const defaultValues = isEditing
    ? { ...model }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        consentType: '',
        consentDate: new Date(),
        reponse: '',
        showInFamily: false,
        obtainedBy: loggedInUser._id
      };

  const [formValues, setFormValues] = React.useState(defaultValues);

  const [typeOptions] = React.useState([]);

  const [error, setError] = React.useState({});

  const handleChange = (e) => {
    if (e.target.type === 'date') {
      const selectedDate = new Date(e.target.value);
      const today = new Date();

      if (selectedDate >= today) {
        setError((prev) => ({ ...prev, [e.target.name]: true }));
      } else setError({});
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };
  const { setSnack } = React.useContext(SnackContext);
  const { theme } = React.useContext(ThemeContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const deleteNote = async (e) => {
    setLoading(true);

    const dep = await getDependencies(
      user.familyId,
      model._id,
      theme.dateFormat
    );
    if (dep.length !== 0) {
      setSnack({
        status: true,
        msg: `Associated record exists; check the ${dep[0]}`,
        severity: 'error'
      });
      setLoading(false);

      return;
    }
    axios
      .delete(`/clinical-notes/note`, {
        params: {
          _id: model._id
        }
      })
      .then((res) => {
        setSnack({
          status: true,
          msg: 'Record deleted',
          severity: 'success'
        });
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Delete failed! ${err}`,
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (Object.keys(error).length > 0) return Promise.resolve();
    let obtainedBy = null;
    if (formValues.obtainedBy) {
      obtainedBy = staffMembers.find(
        (member) => member.userId === formValues.obtainedBy
      );
    }

    const parsePayload = {
      ...formValues,
      obtainedBy: obtainedBy ? [obtainedBy] : []
    };

    return axios
      .put(APICONSTANTS.consent, parsePayload)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <ClinicalNoteDeleteModal
        loading={loading}
        open={open}
        handleCloseModal={handleCloseModal}
        onConfirm={deleteNote}
        title="Are you sure you want to delete this Consent ?"
        content="Warning: This action will permanently delete this Consent. Once deleted, this
        information will be permanently removed and cannot be restored."
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Consent Type"
              type="autocomplete"
              name="consentType"
              value={formValues.consentType || ''}
              onChange={(e) => {
                const label = e ? e.label : '';
                setFormValues({
                  ...formValues,
                  consentType: label
                });
              }}
              options={typeOptions || []}
              helperText="Please select or enter a consent type"
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Consent Date</FormLabel>
          </Grid>
          <DateInput
            value={formValues.consentDate}
            disableFuture
            onChange={(val) =>
              handleChange({
                target: { name: 'consentDate', value: val, type: 'date' }
              })
            }
            variant="outline"
            error={error?.consentDate}
            helperText={'Date cannot be in the future'}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Response</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="response"
              value={formValues.response}
              multiline={true}
            />
          </Box>
        </Grid>

        {/* ObtainedBy */}
        <Grid item xs={12} id="top">
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Obtained By </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              fullWidth={true}
              variant="outlined"
              name="obtainedBy"
              options={staffMemberOptions}
              value={formValues.obtainedBy || ''}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          addLabel={`Add new consent details`}
          intermediateBtnLabel="Delete"
          intermediateAction={isEditing ? handleOpenModal : null}
        />
      </Grid>
    </form>
  );
}
