import React, { useCallback, useContext, useMemo } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Button,
  Checkbox,
  Box
} from '@mui/material';
import DateInput from 'src/components/UI/input/DateInput';
import { formatDate, validateNumber, validatePhn } from 'src/util/util';
import moment from 'moment';

import Switch from '@mui/material/Switch';
import FormLabel from 'src/components/UI/input/CustomFormLabel';
import {
  CONSTANTS,
  allEthnicities,
  ethnicityMap,
  spouseRelations
} from 'src/components/questionnaire/Constants';
import SelectInput from 'src/components/UI/input/SelectInput';
import RadioInput from 'src/components/UI/input/RadioInput';
import TextInput from 'src/components/UI/input/TextInput';
import { ThemeContext, SnackContext } from 'src/store/ContextStore';
import { validateEmail } from 'src/util/util';
import MultipleSelect from 'src/components/UI/input/MultipleSelectInput';
import {
  getConnectedChildren,
  getSpousesWithChildren
} from '../PedigreeChart/utils';
import { checkRelationValid } from './util';

const defaultValue = {
  gender: 'unknown',
  email: '',
  firstName: '',
  lastName: '',
  dob: '',
  dod: '',
  estimatedDob: false,
  estimatedDod: false,
  mobile: '',
  isAlive: 'true',
  diagnoses: [],
  diagnosesAge: {}
};

function PersonMenu({
  data,
  handleDetailsChange,
  deepCopy,
  setDeepCopy,
  setDataset,
  setError,
  handleClose
}) {
  const [formValues, setFormValues] = React.useState(defaultValue);
  const { theme } = useContext(ThemeContext);

  const [DOBErrorText, setDOBErrorText] = React.useState('');
  const [DOBError, setDOBError] = React.useState(false);
  const isDOBValid = () => {
    return (
      !formValues.dob ||
      (moment(new Date()) > moment(formValues.dob) &&
        (!formValues.dod || !(moment(formValues.dod) < moment(formValues.dob))))
    );
  };

  const [DODErrorText, setDODErrorText] = React.useState('');
  const [DODError, setDODError] = React.useState(false);
  const isDODValid = () => {
    return (
      !formValues.dod ||
      (moment(new Date()) > moment(formValues.dod) &&
        (!formValues.dob || !(moment(formValues.dod) < moment(formValues.dob))))
    );
  };

  // calculating total spouse and its childrens
  const totalSpouse = useMemo(() => {
    if (data?.data && data.data?.spouse) {
      return getSpousesWithChildren(data.data, deepCopy);
    }
  }, [data?.data, deepCopy]);

  // return parent realtionship and consanguinity status from child
  const getParentInfoFromChildren = useCallback(
    (selectedSpouseValue) => {
      if (!totalSpouse?.length)
        return {
          parentRelationship: spouseRelations.MARRIED,
          isParentConsanguineous: false
        };

      // Finding all the connected children
      const connectedChildren =
        getConnectedChildren(totalSpouse, selectedSpouseValue) || [];

      const child = connectedChildren[0] || {};

      const parentRelationship =
        child?.parentRelationship || spouseRelations.MARRIED;
      const isParentConsanguineous = child?.isParentConsanguineous || false;

      return { parentRelationship, isParentConsanguineous };
    },
    [totalSpouse]
  );

  // adding parent relationship and consanguineous keys in children
  const addParentInfoToChildren = (
    children = [],
    relationship,
    consanguinity
  ) => {
    if (children.length) {
      children.forEach((child) => {
        child.parentRelationship = relationship;
        child.isParentConsanguineous = consanguinity;
      });
    }
    return children;
  };

  const handleChange = (e) => {
    const value =
      typeof e.target.value === 'string'
        ? e.target.value.trim()
        : e.target.value;

    if (e.target.name === 'genderIdentity') {
      const relationValid = checkRelationValid(
        CONSTANTS.genderMappings[value],
        deepCopy,
        formValues
      );

      if (!relationValid.status) {
        setError(relationValid.message);
        return;
      }

      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: value,
        gender: CONSTANTS.genderMappings[value]
      }));
    }
    if (e.target.type === 'checkbox') {
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.checked
      }));
    } else {
      const additionalFormValues = {};
      if (e.target.name === 'selectedSpouse') {
        const { isParentConsanguineous, parentRelationship } =
          getParentInfoFromChildren(e.target.value);

        additionalFormValues.spouseRelationship = parentRelationship;
        additionalFormValues.isConsanguineous = isParentConsanguineous;
      }

      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: value,
        ...(e.target.name === 'isAlive' && value === 'true' ? { dod: '' } : {}),
        ...additionalFormValues
      }));
    }
  };

  const handleDiagnosisAgeChange = (e) => {
    if (e.target.value === '' || validateNumber(e.target.value)) {
      setFormValues({
        ...formValues,
        diagnosesAge: {
          ...formValues.diagnosesAge,
          [e.target.name]: e.target.value
        }
      });
    }
  };

  const dateChangeHandler = (type, value) => {
    let formattedDate = formatDate(value);
    let invalidDate = formattedDate.includes('NaN');

    setFormValues((prevState) => ({
      ...prevState,
      [type]: value,
      ...(type === 'dod' && !invalidDate ? { isAlive: 'false' } : {})
    }));
  };

  React.useEffect(() => {
    if (data.data) {
      const formValuesData = {
        pid: data.data.pid,
        spouse: data.data.spouse ?? null,
        gender: data.data.gender ? data.data.gender : 'unknown',
        genderIdentity: data.data.genderIdentity,
        email: data.data.email ?? '',
        firstName: data.data.firstName ?? '',
        lastName: data.data.lastName ?? '',
        dob: data.data.dob ?? null,
        dod: data.data.dod ?? null,
        estimatedDod: data.data['estimatedDod'],
        estimatedDob: data.data['estimatedDob'],
        mobile: data.data.mobile ?? '',
        isAlive: data.data.isAlive ?? (data.data.dod ? 'false' : ''),
        diagnoses: data.data.diagnoses ?? [],
        diagnosesAge: data.data.diagnosesAge ?? {},
        isHide: data.data.isHide ?? false,
        ethnicity: data.data.ethnicity ?? [],
        hospitalNumber: data.data.hospitalNumber ?? '',
        healthcareId: data.data.healthcareId ?? ''
      };
      if (!!data.data?.spouse && (!!data.data?.upn || !!data.data?.proBandId)) {
        const { parentRelationship, isParentConsanguineous } =
          getParentInfoFromChildren();
        formValuesData.totalSpouse = totalSpouse;
        formValuesData.spouseRelationship = parentRelationship;
        formValuesData.isConsanguineous = isParentConsanguineous;
      }
      setFormValues(formValuesData);
    } else {
      setFormValues(defaultValue);
    }
  }, [data]);

  const handleSubmit = () => {
    const updateChangedNodes = [];
    const updateConnectedChildren = [];

    if (formValues.dob) formValues.dob = formatDate(formValues.dob);
    if (formValues.dod) formValues.dod = formatDate(formValues.dod);

    if ((!!data.data?.upn || !!data.data?.proBandId) && !!formValues?.spouse) {
      const connectedChildren = getConnectedChildren(
        formValues.totalSpouse,
        formValues.selectedSpouse
      );
      // updating children with parent info
      const childrenWithParentInfo = addParentInfoToChildren(
        connectedChildren,
        formValues.spouseRelationship,
        formValues.isConsanguineous
      );
      updateConnectedChildren.push(...childrenWithParentInfo);
      // deleting keys
      delete formValues.spouseRelationship;
      delete formValues.isConsanguineous;
      delete formValues.selectedSpouse;
      delete formValues.totalSpouse;
    }

    // Find the target node to update
    const node = deepCopy.filter((p) => p.pid === data.data.pid)[0];

    const updatedNode = { ...node, ...formValues };

    updateChangedNodes.push(updatedNode);

    if (updateConnectedChildren.length) {
      updateChangedNodes.push(...updateConnectedChildren);
    }

    const newDataset = deepCopy.map((person) => {
      const matchedNode = updateChangedNodes.find(
        (node) => node.pid === person.pid
      );
      if (matchedNode) {
        return { ...person, ...matchedNode };
      }
      return person;
    });

    handleDetailsChange(updateChangedNodes);
    // setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(newDataset));
    });
    setDataset(newDataset);
  };

  React.useEffect(() => {
    let err = !isDOBValid();
    setDOBError(err);

    err = !isDODValid();
    setDODError(err);
  }, []);

  const validDates = () => {
    return isDOBValid() && isDODValid();
  };

  const { setSnack } = React.useContext(SnackContext);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <div style={{ maxWidth: 600 }} className="user-form">
        <Grid container spacing={2}>
          {/* gender */}
          <Grid item xs={12} id="top">
            <FormControl component="fieldset">
              <Grid container spacing={1}>
                <Grid item>
                  <FormLabel>Gender </FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <SelectInput
                    fullWidth={true}
                    variant="outlined"
                    label={formValues.genderIdentity}
                    name="genderIdentity"
                    options={CONSTANTS.genderIdentityOptions}
                    value={
                      formValues.genderIdentity ??
                      formValues?.gender[0].toUpperCase() +
                        formValues.gender.substring(1)
                    }
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <div className="margin-top-3"></div>
            <RadioInput
              label="Sex Assigned at Birth"
              name="gender"
              value={formValues.gender || ''}
              options={CONSTANTS.genderOptions}
              row
              disabled
            />
          </Grid>

          {/* isAlive */}
          <Grid item xs={12}>
            <div className="margin-top-3"></div>
            <FormControl component="fieldset">
              <FormLabel component="label" className="form-label">
                Alive or Deceased?
              </FormLabel>
              <RadioGroup
                aria-label="isAlive"
                name="isAlive"
                value={formValues.isAlive ?? ''}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio size="small" />}
                  label="Alive"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio size="small" />}
                  label="Deceased"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* name */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  First Name
                </FormLabel>
                <TextInput
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Last Name
                </FormLabel>
                <TextInput
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* date */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Date of Birth
                </FormLabel>

                <DateInput
                  value={formValues.dob}
                  onChange={(date) => {
                    setDOBError(false);
                    setDODError(false);
                    dateChangeHandler('dob', date);
                  }}
                  disableFuture
                  error={DOBError}
                  helperText={DOBErrorText}
                  liveText={true}
                  helperTextColour="red"
                  slotProps={{ textField: { fullWidth: true } }}
                />

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="estimatedDob"
                        checked={formValues['estimatedDob']}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Exact Date Unknown"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Date of Death
                </FormLabel>
                <DateInput
                  format={theme.dateFormat}
                  disabled={formValues.isAlive === 'true'}
                  value={formValues.dod}
                  onChange={(date) => {
                    setDOBError(false);
                    setDODError(false);
                    dateChangeHandler('dod', date);
                  }}
                  disableFuture
                  error={DODError}
                  helperText={DODErrorText}
                  liveText={true}
                  helperTextColour="red"
                  slotProps={{ textField: { fullWidth: true } }}
                />
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="estimatedDod"
                        checked={formValues['estimatedDod']}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Exact Date Unknown"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* ethnicity */}
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    label={'Ethnicity'}
                    name="ethnicity"
                    type="multiple-select"
                    value={formValues?.ethnicity || []}
                    onChange={handleChange}
                    options={allEthnicities}
                    mapper={ethnicityMap}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          {!!formValues.spouse && !!formValues.totalSpouse?.length && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {/* Spouse Relationship */}
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="flex-end">
                    {formValues.totalSpouse.length > 1 && (
                      <Grid item xs={5}>
                        <FormControl component="fieldset" fullWidth>
                          <Grid container spacing={1}>
                            <Grid item>
                              <FormLabel>Select Spouse</FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                              <SelectInput
                                fullWidth={true}
                                variant="outlined"
                                label={'Select Spouse'}
                                name="selectedSpouse"
                                options={formValues.totalSpouse}
                                value={formValues.selectedSpouse}
                                onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormControl component="fieldset" fullWidth>
                        <Grid container spacing={1}>
                          <Grid item>
                            <FormLabel mLabel>Spouse Relationship</FormLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <SelectInput
                              fullWidth={true}
                              variant="outlined"
                              label={'Spouse Relationship'}
                              name="spouseRelationship"
                              options={CONSTANTS.spouseRelationshipOptions}
                              value={formValues.spouseRelationship}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Consanguinity status */}
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={5}>
                      <FormLabel component="label" className="form-label">
                        Consanguineous
                      </FormLabel>
                    </Grid>
                    <Grid item xs={7}>
                      <Switch
                        checked={formValues.isConsanguineous}
                        onChange={handleChange}
                        name="isConsanguineous"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* Hide / Unhide */}
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5}>
                <FormLabel component="label" className="form-label">
                  Hide on Pedigree
                </FormLabel>
              </Grid>
              <Grid item xs={7}>
                <Switch
                  checked={formValues.isHide}
                  onChange={handleChange}
                  name="isHide"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="margin-top-3"></div>
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              {/* contact */}
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Mobile
                </FormLabel>
                <TextInput
                  name="mobile"
                  value={formValues.mobile}
                  onChange={(e) => {
                    if (validatePhn(e.target.value) || e.target.value === '')
                      handleChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  updateParentOnBlur
                />
              </Grid>

              {/* Hospital Number */}
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Hospital Number
                </FormLabel>
                <TextInput
                  name="hospitalNumber"
                  value={formValues.hospitalNumber}
                  onChange={handleChange}
                />
              </Grid>

              {/* Healthcare ID */}
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Healthcare ID
                </FormLabel>
                <TextInput
                  name="healthcareId"
                  value={formValues.healthcareId}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={8}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    /* CHECKS VALIDITY OF EMAIL; COMMENTED OUT BECAUSE IT PREVENTS EMAIL BEING REMOVED AND MADE BLANK AGAIN.
                  if (!validateEmail(formValues.email)) {
                    setSnack({
                      status: true,
                      msg: 'Email is not valid',
                      severity: 'warning'
                    });
                    return;
                  }
                    */
                    if (!validDates()) {
                      const element = document.getElementById('top');
                      element.scrollIntoView();
                      setDODErrorText('DOD cannot be in future or before DOB');
                      setDODError(true);
                      if (!isDOBValid()) {
                        setDOBErrorText('DOB cannot be in future or after DOD');
                        setDOBError(true);
                      }
                      setSnack({
                        status: true,
                        msg: 'Dates were not valid',
                        severity: 'warning'
                      });
                      return;
                    }
                    handleSubmit();
                  }}
                  size="medium"
                  variant="contained"
                  color="secondary"
                  disableElevation
                >
                  Save
                </Button>
              </Grid>

              <Grid item xs={4}>
                <Button
                  size="medium"
                  variant="outlined"
                  onClick={handleClose}
                  disableElevation
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default PersonMenu;
