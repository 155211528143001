import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import AssociatedNote from '../../../UI/input/AssociatedNote';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';

import axios from 'src/axios';

import { Box, TextField } from '@mui/material';
import DateInput from '../../../UI/input/DateInput';

import { formatDate } from 'src/util/util';
import { SnackContext, AppContext } from 'src/store/ContextStore';
import { getConsentTags } from './formService';
import { ThemeContext, UserContext } from 'src/store/ContextStore';
import { getDependencies } from './formService';
import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';
import SelectInput from 'src/components/UI/input/SelectInput';

export default function SampleForm({
  handleClose,
  familyMemberId,
  isEditing,
  model,
  staffMembers,
  staffMemberOptions
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { theme } = useContext(ThemeContext);
  const { user: loggedInUser } = React.useContext(UserContext);

  const defaultValues = isEditing
    ? { ...model }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        sampleType: '',
        dateTaken: new Date(),
        trackingId: '',
        sampleStatus: '',
        associatedConsent: { label: 'none', _id: '' },
        obtainedBy: loggedInUser._id
      };

  const [formValues, setFormValues] = React.useState(defaultValues);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState({});

  const handleChange = (e) => {
    if (e.target.type === 'date') {
      const selectedDate = new Date(e.target.value);
      const today = new Date();

      if (selectedDate >= today) {
        setError((prev) => ({ ...prev, [e.target.name]: true }));
      } else setError({});
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const { setSnack } = React.useContext(SnackContext);
  let [consentOptions, setConsentOptions] = React.useState(['loading...']);
  React.useEffect(() => {
    const fetchData = async () => {
      const consentTags = await getConsentTags(
        theme,
        familyMemberId,
        formValues.familyId
      );
      setConsentOptions(consentTags);
    };
    if (consentOptions != [] && consentOptions[0] == 'loading...') fetchData();
  }, []);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const deleteNote = async (e) => {
    setLoading(true);

    const dep = await getDependencies(
      user.familyId,
      model._id,
      theme.dateFormat
    );
    if (dep.length !== 0) {
      setSnack({
        status: true,
        msg: `Associated record exists; check the ${dep[0]}`,
        severity: 'error'
      });
      setLoading(false);
      return;
    }
    axios
      .delete(`/clinical-notes/note`, {
        params: {
          _id: model._id
        }
      })
      .then((res) => {
        setSnack({
          status: true,
          msg: 'Record deleted',
          severity: 'success'
        });
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Delete failed! ${err}`,
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (Object.keys(error).length > 0) return Promise.resolve();
    let obtainedBy = null;
    if (formValues.obtainedBy) {
      obtainedBy = staffMembers.find(
        (member) => member.userId === formValues.obtainedBy
      );
    }

    const parsePayload = {
      ...formValues,
      obtainedBy: obtainedBy ? [obtainedBy] : []
    };

    return axios
      .put(APICONSTANTS.sample, parsePayload)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <ClinicalNoteDeleteModal
        loading={loading}
        open={open}
        handleCloseModal={handleCloseModal}
        onConfirm={deleteNote}
        title="Are you sure you want to delete this Sample ?"
        content="Warning: This action will permanently delete this Sample. Once deleted, this
        information will be permanently removed and cannot be restored."
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Tracking ID</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="trackingId"
              value={formValues.trackingId}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Sample Status</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="sampleStatus"
              value={formValues.sampleStatus}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Sample Type</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="sampleType"
              value={formValues.sampleType}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Date Taken</FormLabel>
          </Grid>
          <DateInput
            name="dateTaken"
            value={formValues.dateTaken ? new Date(formValues.dateTaken) : null}
            disableFuture
            onChange={(val) =>
              handleChange({
                target: { name: 'dateTaken', value: val, type: 'date' }
              })
            }
            variant="outline"
            error={error?.dateTaken}
            helperText={'Date cannot be in the future'}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Associated Consent</FormLabel>
          </Grid>
          <AssociatedNote
            name="associatedConsent"
            handleChange={handleChange}
            value={formValues.associatedConsent}
            noteOptions={consentOptions}
          />
        </Grid>

        {/* ObtainedBy */}
        <Grid item xs={12} id="top">
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Obtained By </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              fullWidth={true}
              variant="outlined"
              name="obtainedBy"
              options={staffMemberOptions}
              value={formValues.obtainedBy || ''}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          intermediateBtnLabel="Delete"
          intermediateAction={isEditing ? handleOpenModal : null}
          addLabel={`Add new sample details`}
        />
      </Grid>
    </form>
  );
}
