import React from 'react';
import Table from 'src/components/UI/table/Table';
import axios from 'src/axios';
import { capitalizeLetter } from 'src/util/util';
import { APICONSTANTS, CONSTANTS, TABLE_CONSTANTS } from '../Constants';
import { LoadingContext, SnackContext } from 'src/store/ContextStore';
import AddFamily from './AddFamily';
import { useHistory } from 'react-router-dom';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { Chip } from '@mui/material';

export default function SelectFamily(props) {
  const { setLoading } = React.useContext(LoadingContext);
  const { setSnack } = React.useCallback(SnackContext);
  const [selectedId, setSelectedId] = React.useState(-1);

  const [query, setQuery] = React.useState({ page: 0, pageSize: 10 });

  const [pedigreeList, setPedigreeList] = React.useState({
    data: [],
    page: 0,
    totalCount: 0
  });

  React.useEffect(() => {
    if (pedigreeList.data.length === 0) {
      setLoading(true);
    }

    axios
      .get(
        APICONSTANTS.getProbandUsers +
          `?page=${query.page}&limit=${query.pageSize}&filter=true`
      )
      .then((res) => {
        let rows = res.data.data ?? [];
        props.setRows(rows);
        setPedigreeList({
          data: rows,
          page: res.data.page - 1,
          totalCount: res.data.totalCount
        });

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setSnack({
          status: true,
          msg: 'Error fetching values',
          severity: 'error'
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const history = useHistory();

  const t = useAutoTranslation();

  const columns = [
    {
      title: '#',
      render: (rowData) => rowData.tableData.id + 1,
      width: '10%',
      sorting: false
    },
    { title: t('Family ID'), field: 'familyId', width: 100 },
    { title: 'TrakGene ID', field: 'trakGeneId', width: 100 },
    {
      title: t('First Name'),
      field: 'firstName',
      width: 200,
      render: (rowData) => capitalizeLetter(rowData.firstName)
    },
    {
      title: t('Last Name'),
      field: 'lastName',
      width: 200,
      render: (rowData) => capitalizeLetter(rowData.lastName)
    },
    {
      title: t('Number of People'),
      field: 'none',
      render: (rowData) => rowData.familyMembers.length + 1,
      width: 50
    },
    {
      title: t('Tags'),
      field: 'tags.tag',
      width: 300,
      render: (rowData) => {
        if (rowData.tags) {
          const chips = rowData.tags.map(({ tag, tagColor }) => (
            <Chip
              key={tag}
              label={tag}
              size="small"
              style={{
                backgroundColor: tagColor,
                fontWeight: 500
              }}
            />
          ));
          return <div style={{ display: 'flex', gap: 2 }}>{chips}</div>;
        }
      },
      customFilterAndSearch: (term, rowData) => {
        if (rowData.tags) {
          const tagsText = rowData.tags
            .map(({ tag }) => tag && tag.toLowerCase())
            .join(' ');
          return tagsText.includes(term.toLowerCase());
        }
        return false;
      }
    }
  ];

  const onRowClick = (rowData) => {
    setLoading(true);
    // fetch family details for the selected row
    axios
      .get(APICONSTANTS.getFamiliesInfo.replace(':user_id', rowData._id))
      .then((res) => {
        setSelectedId(rowData.tableData.id);
        setLoading(false);
        props.setUsers({ family: res.data, selectedUser: rowData });
        history.push(
          props.patient
            ? CONSTANTS.patientPedigreeChartPath.replace(
                ':_id',
                rowData.familyId
              )
            : CONSTANTS.pedigreeChartPath.replace(':_id', rowData.familyId)
        );
      })

      .catch(() => {
        setLoading(false);
      });
  };

  const [open, setOpen] = React.useState(false);

  const addClicked = () => {
    setOpen(true);
  };

  const pedigreeTable = React.useMemo(() => {
    return (
      <Table
        // buttonStyle={{ marginLeft: 230 }}
        rows={pedigreeList.data}
        columns={columns}
        page={pedigreeList.page}
        pageSize={query.pageSize}
        totalCount={pedigreeList.totalCount}
        onChangePage={(newPage) =>
          setQuery((prev) => ({ ...prev, page: newPage }))
        }
        onChangeRowsPerPage={(newPageSize) =>
          setQuery((prev) => ({ ...prev, pageSize: newPageSize }))
        }
        addClicked={!props.patient && addClicked}
        addText="Add New Family"
        onRowClick={onRowClick}
        pageSizeOptions={[5, 10, 15, 20, 30, 40, 50]}
        title=""
        dense={true}
        highlightOnSelect={true}
        selectedTableDataId={selectedId}
        highlightColor={'#f7e406'}
        tableKey={TABLE_CONSTANTS.pedigree}
      />
    );
  }, [pedigreeList]);

  return (
    <>
      {!props.patient && (
        <AddFamily
          open={open}
          setOpen={setOpen}
          setPedigreeList={setPedigreeList}
        />
      )}
      {pedigreeTable}
    </>
  );
}
